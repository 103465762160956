<template>
  <NuxtLink
    :to="{
      name: indexStore.currentPaperSlug ? 'paper_person' : 'person',
      params: {
        person: person.urlKey || person.UrlKey,
        paper: indexStore.currentPaperSlug,
      },
    }"
    class="group block"
  >
    <div
      class="flex cursor-pointer z-10 relative"
      :class="
        isMm
          ? ''
          : 'mb-3 md:mb-4 bg-white border border-gray-300 rounded-xl md:-ml-3 md:-mr-3 p-3'
      "
    >
      <nuxt-img
        class="rounded-full shrink-0 bg-black w-15 h-15 mr-3 overflow-hidden object-cover object-center"
        width="64"
        loading="lazy"
        :src="
          (person.masterId && person.masterId !== 0) ||
          (person.MasterId && person.MasterId !== 0)
            ? 'https://legacy.altinget.dk/images/person/' +
              (person.masterId || person.MasterId) +
              '/' +
              (person.image || person.Image)
            : config.public.site.legacydomain +
              '/images/person/' +
              (person.recordId || person.RecordId || person.id) +
              '/' +
              (person.image || person.Image)
        "
      />
      <div>
        <h4 class="list-title-s mb-1">
          {{ person.name || person.Name }}
        </h4>
        <div class="list-title-xxxs !font-medium">
          {{ person.title || person.Title }}
        </div>
        <div class="body-xs text-gray-500">
          {{ person.education || person.Education }}
        </div>
      </div>
      <div v-if="showContactButton" class="absolute right-0 pr-3">
        <a>
          <CommonButton
            class="cursor-pointer label-s"
            bg="bg-red"
            text="text-white"
          >
            Kontakt
          </CommonButton>
        </a>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
const { isMm } = useBrand()
const props = withDefaults(
  defineProps<{ person?: any; showContactButton?: boolean }>(),
  { person: {}, showContactButton: false }
)
const indexStore = useIndexStore()
const config = useRuntimeConfig()

const currentPaper = computed(() => {
  if (indexStore.currentPaper && indexStore.currentPaper.RecordId !== 1) {
    return `/${indexStore.currentPaper.Url}`
  } else {
    return ''
  }
})
</script>
